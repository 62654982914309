.iqitvideos-block{
  > .iqitvideo{  max-width: 100%;}
  > .iqitvideo > .iframe{width: 100%; border: none;}
}

#iqit-threesixty{
  text-align: center;
  cursor: grab;
  cursor: -webkit-grab;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }

  .threesixty--grabbing {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
}

.tridi-loading {
min-height: 100px;
}


@-webkit-keyframes spintri {
  from {-webkit-transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);}
}

@keyframes spintri {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.tridi-spinner:after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color:hsla(0,0%,63%,.25);
  border-top-color:transparent;
  border-width: 4px;
  border-radius:50%;
  -webkit-animation: spintri .8s linear infinite;
  animation: spintri .8s linear infinite;
}

.tridi-viewer,
.tridi-viewer * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}
.tridi-viewer {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  .icon-tidi-load{
    display: none;
  }
}
.tridi-viewer-image {
  width: 100%;
  margin: auto;
  animation: fadeIn 0.5s ease forwards;
}
.tridi-stash {
  display: none;
}

.video-thumb-container{
  width: 100px;
  > .iframe{width: 100%; border: none;}
}